import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;

  #containe-busca-produto {
    padding: 0;
    margin: 0;

    button {
      width: 100%;
    }
  }
  .disabled-linear-progress {
    div {
      background: transparent !important;
    }
  }

  .toogle-inline {
    .toggleButton {
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
    }
  }
  th {
    white-space: nowrap;
  }
  .tipo-0 {
    background: rgb(123, 66, 180) !important;
  }
  .tipo-1 {
    background: rgb(136, 80, 191) !important;
  }
  .tipo-2 {
    background: rgb(123, 66, 180) !important;
  }
  .tbody-margem tr td,
  .thead-margem tr th {
    min-width: 130px;
  }
  .MargemRef {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }
  .alingLeft {
    height: 70px;
    text-align: right;
  }

  .tabelaPreco .single-select {
    z-index: 1 !important;
  }

  @media screen and (max-width: 767px) {
    .containerTitle {
      max-width: 200px;
    }
    .containerChild {
      max-width: 200px !important;
      padding-left: 0px !important;
    }
  }
`;
export const Title = styled.h3`
  margin-top: 20px;
  margin-bottom: 0px;
  color: #313638;
  font-size: calc(1.3rem + 0.6vw);
  line-height: 40px;
`;
export const ButtonForm = styled.button`
  padding: 3px 12px;
  height: 42px;
  border-radius: 4px;
  border: none;
  width: 160px;
  background-color: #28a745;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 16px;

  svg {
    font-size: 20px;
    margin-right: 10px;
  }

  margin-right: 8px;
  margin-left: 8px;

  :disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
  }

  :disabled:hover {
    opacity: 1;
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const SeparatorLine = styled.p`
  margin-top: 40px;
  margin-bottom: 0px;
  border-bottom: 1px solid #ededed;
  height: 5px;
  width: 99.95%;
`;

export const ButtonCancel = styled.button`
  height: 40px;
  min-width: 85px;
  border-radius: 4px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;
  animation: all 0.25s ease-in;
  margin: 0 5px;
  background-color: #dc3545;
  &:disabled {
    background-color: #e3bec1;
    cursor: not-allowed;
    &:hover {
      background-color: #e3bec1;
      opacity: 1;
    }
    // eslint-disable-next-line prettier/prettier
  }
  &:hover {
    opacity: 0.7;
  }
  box-shadow: transparent !important;
`;
export const ContainerLoader = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
